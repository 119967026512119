<template>
  <div>
    <div v-if="brandDateTime">
      <div
        class="company-brand"
        :class="[hideBrandDateTime ? 'fade-out' : 'fade-in']"
      >
        <svg-icon
          src="/icons/rol_intelligent_office_logo.svg"
          width="1px"
          height="1px"
        />
      </div>
      <div
        class="date-time"
        :class="[hideBrandDateTime ? 'fade-out' : 'fade-in']"
      >
        <DateTimeKeeper />
      </div>
    </div>
    <div
      class="recenter-button"
      :class="[!brandDateTime || hideBrandDateTime ? 'slide-down' : 'slide-up']"
      @click="MapNavigatorController.reset()"
    >
      <ProgressBarCircle
        :class="showIdleProgressCircle ? 'fade-in' : 'fade-out'"
        :show-backdrop="false"
        :percentage="timerActive ? idleProgressPercentage : 100"
        :progress-color="progressBarColor"
        :stroke-width="5"
        :size="56"
        :shadow-offset="false"
      />
      <svg-icon
        src="/icons/default_view_navigator.svg"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import MapState from '@/singletons/map.state.singleton';
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import DateTimeKeeper from './datetime.keeper.vue';
import ProgressBarCircle from '@/views/reminders.timer/components/circle.component.vue';
import SettingsNavigatorState from "@/views/settings/navigator/settings.navigator.state";

export default {
  components: {
    DateTimeKeeper,
    ProgressBarCircle,
  },
  props: {
    brandDateTime: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      MapState,
      MapNavigatorController,
      hideBrandDateTime: false,
      timerActive: true,
      progressBarColor: 'var(--primary-color)',
      progressBreakpoint: 1.0/3.0, // When to begin showing the progress
    };
  },
  computed: {
    showIdleProgressCircle() {
      // Only show the circle if user is idle and the timer has exceeded the breakpoint
      // 33% -> 100%
      const idleTimePercentage = SettingsNavigatorState.idleTimeCounter / SettingsNavigatorState.idleDuration;
      return !SettingsNavigatorState.idle
        && idleTimePercentage > this.progressBreakpoint
        && idleTimePercentage < 1.0;
    },
    idleProgressPercentage() {
      // Alter the progress to start at progressBreakpoint going up to 100
      // Transforms 33% -> 100% to 0% -> 100%
      if (!SettingsNavigatorState.idleDuration) return 0;
      const partialCut = SettingsNavigatorState.idleDuration * this.progressBreakpoint;
      const percentage = 100 * ((SettingsNavigatorState.idleTimeCounter - partialCut) / (SettingsNavigatorState.idleDuration - partialCut));
      return Math.min(Math.max(percentage, 0), 100);
    }
  },
  watch: {
    'MapState.camera.zoom'() {
      this.hideBrandDateTime = MapState.camera?.zoom > 5;
    },
  },
};
</script>

<style lang="scss">
.recenter-button {
  position: absolute;
  z-index: 999;
  right: 0%;
  margin-right: 1.5rem;
  path {
    fill: #12823e;
  }
  .icon {
    display: flex;
    padding: 2px;
  }
  .circle-progress {
    position: absolute;

    &.fade-in {
      opacity: 1;
      transition: opacity 0.8s ease-in-out;
    }

    &.fade-out {
      opacity: 0;
    }
  }
  filter: url(#Ellipse_893);
  :hover {
    cursor: pointer;
  }
  &.slide-down{
     bottom: 1.5rem;
     transition: 0.5s bottom 0.5s ease-in-out;
   }
  &.slide-up {
     bottom: 7.5rem;
     transition: bottom 0.5s ease-in-out;
   }
}

.company-brand, .date-time {
  position: absolute;
  z-index: 999;
  bottom: 1.5rem;
}
</style>
