<template>
  <div
    v-show="MapState.selectedZone"
    class="navigator_popup_container"
  >
    <div
      id="navigator_popup"
      class="navigator_popup"
      @click.stop="() => {}"
    >
      <div class="map-popup-timeline-time_container">
        <RoundResourceIcon
          :src="resource?.Icon || '/icons/default_resource.svg' "
          :type="stateForIcon"
        />
        <div
          class="map-popup-information-side"
        >
          <h6 class="normal-semi">
            {{ resource?.Name }}
          </h6>
          <p
            v-if="resource?.Bookable && booking"
            class="normal-regular"
          >
            {{ `${stateString(true)} ${$t('Generic.Until')} ${getTimeString}` }}
          </p>
          <p
            v-else-if="!resource?.Bookable"
            class="normal-regular"
          >
            {{ `${stateString(true)}` }}
          </p>
          <div
            v-else-if="resource.Bookable"
            class="loading-string"
          />
        </div>
      </div>

      <!-- Bottom section -->
      <div class="border_line" />
      <div
        v-if="user.Name && !resourceAvailable"
        class="map-popup-user-side"
      >
        <person-avatar
          height="40px"
          width="40px"
          :user="user"
          :src="user.AvatarUrl"
        />
        <p class="normal-regular text-overflow">
          {{ user.Name }}
        </p>
      </div>
      <div
        v-if="user.Name && !resourceAvailable"
        class="border_line"
      />
      <div
        v-if="resource?.Type !== 'SPACE'"
        class="navigator_popup_button"
      >
        <Button
          v-if="resource?.Bookable"
          @click="navigateToResourceView"
        >
          {{ $t('Views.Book.book') }}
        </Button>
        <Button
          v-else-if="resource"
          @click="navigateToResourceInformation"
        >
          {{ $t('Views.Book.show_information') }}
        </Button>
      </div>
      <div
        v-else-if="resource"
        class="navigator_popup_button"
      >
        <Button
          :variant="'space'"
          @click="navigateToSpaceView"
        >
          {{ $t('Views.Book.book') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import WeekState from '@/singletons/week.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import ZoneState from '@/singletons/zone.state.singleton';
import SearchService from '@/Services/Search';
import PersonAvatar from '@/components/globals/Person/PersonAvatar.vue';
import ActiveZoneBookings from '@/classes/active.zone.bookings.class';
import ZoneStateService from '@/Services/Zones/zone.state.service';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import Button from '@/components/button/button.vue';
import MapState from '@/singletons/map.state.singleton';
import MapController from '@/classes/map/map.controller';
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import SettingsNavigatorState from '../../settings/navigator/settings.navigator.state';

const MIN_15 = 60 * 15 * 1000;

export default {
  components: {
    PersonAvatar,
    RoundResourceIcon,
    Button,
  },
  data() {
    return {
      SettingsNavigatorState,
      MapState,
      UserState,
      booking: null,
      state: ZoneState.ZONE_STATE,
      userData: null,
      phoneKebabOptions: [],
    };
  },
  computed: {
    resource() {
      return MapState.selectedZone;
    },
    getTimeString() {
      if (!this.booking) {
        return '';
      }
      if (this.booking.From >= Date.now() + MIN_15) { // We have a booking here if it 15 min until
        const from = new ExtendedDate(this.booking.From);
        return `${from.localeTimeString()}`;
      }
      const until = new ExtendedDate(this.booking.Until);
      return `${until.localeTimeString()}`;
    },
    resourceAvailable() {
      return this.stateString() === 'available';
    },
    stateString() {
      return (i18n) => this.getStateString(i18n);
    },
    stateForIcon() {
      if (this?.resource?.Type.toLowerCase().includes('space')) {
        return 'space';
      }
      const state = this.getStateString();
      return state === 'booked' ? 'away' : state;
    },
    user() {
      // Default, take user from booking.
      if (this.resource && !this.resource?.Bookable) {
        const state = ZoneStateService.getRealmStateForZone(this.resource.Zid);
        const email = state?.Properties?.UName;

        if (!email) return {};
        if (email !== this.userData?.Username) {
          this.fetchUserData(email);
        }
        if (this.userData) {
          const { Name, AvatarUrl } = this.userData;
          return { Name, AvatarUrl };
        }
        return { Name: this.$t('Views.Book.guest'), AvatarUrl: null };
      }

      return this.booking?.User || { Name: this.$t('Views.Book.guest'), AvatarUrl: null };
    },
    status() {
      return ZoneState.zoneStates[this?.resource?.Zid]?.Status;
    },
  },
  watch: {
    resourceAvailable(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.invalidate();
      }
    },
    resource(newValue, oldValue) {
      if (!oldValue || newValue?.Zid !== oldValue?.Zid) {
        this.invalidate();
      }
      if (newValue) {
        MapController.selectionManager.panAndZoomToZone(newValue, 10);
      }
    },
  },
  async created() {
    await MapState.hasFinnishedLoadingPromise;
    MapController.selectionManager.onZoneDeselected(this.onZoneDeselected);
    window.addEventListener('map_zoom_controlled', this.onCameraZoomChanged, false);
    MapState.elementNavigatorPopup = document.getElementById('navigator_popup');
  },
  destroyed() {
    MapController.selectionManager.removeListener('on_zone_deselected', this.onZoneDeselected);
  },
  methods: {
    onCameraZoomChanged() {
      MapNavigatorController.reset(false);
    },
    async fetchUserData(email) {
      this.userData = await SearchService.getColleague(email);
    },
    getStateString(i18n = false) {
      switch (this.status) {
        case this.state.AVAILABLE:
          return i18n ? this.$t('Views.Book.available') : 'available';
        case this.state.AWAY:
          return i18n ? this.$t('Views.Book.booked') : 'booked';
        case this.state.OCCUPIED:
          return i18n ? this.$t('Views.Book.occupied') : 'occupied';
        case this.state.UNKNOWN:
        default:
          return i18n ? this.$t('Views.Book.available') : 'available';
      }
    },
    onZoneDeselected() {
      MapController.selectionManager.hideNavigatorResourcePopUp();
      MapState.selectedZone = null;
    },
    // MARK: - Invalidate
    async invalidate() {
      await this.fetchBookingInfo();
    },
    async fetchBookingInfo() {
      this.booking = null;
      if (!this.resource) return;
      const selectedDate = new ExtendedDate(WeekState.date);
      const from = selectedDate.isToday() ? new ExtendedDate().getTime() : selectedDate.setHours(0, 0, 0, 0);
      const until = selectedDate.setHours(24, 0, 0, 0);
      const zoneBookings = new ActiveZoneBookings(this.resource.Zid, from, until, true);
      await zoneBookings.init();
      zoneBookings.caluclateAvailableSlotsInTimeline();
      if (zoneBookings.bookings && zoneBookings.bookings.length > 0) {
        this.booking = zoneBookings.bookings[0];
      }
    },
    // MARK: - Actions
    navigateToResourceView() {
      if (UserState.user?.AbstractUser) {
        this.$router.push(`/navigator/book/${this.resource.Type}/${this.resource.Zid}`);
      } else {
        if (!SettingsNavigatorState.showSidePage) {
          SettingsNavigatorState.showOrHideSidePage(true);
        }
        this.$router.push(`/book/${this.resource.Type}/${this.resource.Zid}/custom`);
      }
    },
    navigateToResourceInformation() {
      if (!UserState.user?.AbstractUser && !SettingsNavigatorState.showSidePage) {
        SettingsNavigatorState.showOrHideSidePage(true);
      }
      this.$router.push(`/book/${this.resource.Type}/${this.resource.Zid}/information`);
    },
    navigateToSpaceView() {
      if (!UserState.user?.AbstractUser && !SettingsNavigatorState.showSidePage) {
        SettingsNavigatorState.showOrHideSidePage(true);
      }
      this.$router.push(`/plan?mapzone=${this.resource.Zid}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.navigator_popup_container {
  width: max-content;
  position: absolute;
}

.navigator_popup {
  display: flex;
  opacity: 0;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  background: white;
  -webkit-box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.1);
  border-radius: 1rem;
}

.navigator_popup_button::after {
  content:'';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  top: 99%;
  border-top: 2rem solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.loading-string {
  width: 5rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  background: var(--primary-color-light);
}

.border_line {
  width: 100%;
  float: left;
  clear: both;
  border: 1px solid #D9D9D9;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.map-popup-timeline-time_container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  align-self: flex-start;

  &.occupied {
    border-left: 8px solid var(--occupied-color);
  }
  &.booked {
    border-left: 8px solid var(--away-color);
  }
  &.available {
    border-left: 8px solid var(--free-color);
  }
  &.unbookable {
    border-left: 8px solid var(--color-resource-other);
  }

  .map-popup-information-side {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    margin-left: 1.18rem;
    flex-grow: 1;
    h5{
      margin: 0;
    }
    p,h6{
      margin: 0;
    }
  }
}
.map-popup-user-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  min-width: fit-content;
  overflow: hidden;

  .avatar--container {
    margin-right: 1.18rem;
  }

  p {
    text-align: end;
    min-width: 0;
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

</style>
