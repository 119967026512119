<template>
  <div
    class="popup"
    @click.stop="() => {}"
  >
    <div
      class="map-popup-timeline-time_container margin margin-medium"
      :class="stateString(false)"
    >
      <div
        class="map-popup-information-side margin margin-medium margin-left"
      >
        <h6 class="normal-semi">
          {{ resource.Name }}
        </h6>
        <h6 class="normal-regular">
          {{ stateString(true) }}
        </h6>
        <p
          v-if="resource && resource.Bookable && booking"
          class="normal-regular"
        >
          {{ getTimeString }}
        </p>
        <div
          v-else-if="resource && resource.Bookable"
          class="loading-string"
        />
      </div>
      <div
        v-if="user.Name && !resourceAvailable"
        class="map-popup-user-side"
      >
        <person-avatar
          height="40px"
          width="40px"
          :user="user"
          :src="user.AvatarUrl"
        />
        <p class="normal-regular text-overflow">
          {{ user.Name }}
        </p>
      </div>
    </div>
    <Kebab :options="phoneKebabOptions" />
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';
import ZoneState from '@/singletons/zone.state.singleton';
import SearchService from '@/Services/Search';
import UserState from '@/singletons/user.state.singleton';
import PersonAvatar from '@/components/globals/Person/PersonAvatar.vue';
import Kebab from '@/components/kebab.menu.vue';
import ActiveZoneBookings from '@/classes/active.zone.bookings.class';
import BookingService from '@/Services/Bookings/bookings.service';
import BookingSuggestionsService from '@/Services/Bookings/booking.suggestions.service';
import ZoneStateService from '@/Services/Zones/zone.state.service';
import ErrorMessage from '@/Services/Error/Error';
import CustomEvent from '@/classes/custom.event.class';
import { deleteBooking } from '@/util/api';

export default {
  components: {
    PersonAvatar,
    Kebab,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      booking: null,
      state: ZoneState.ZONE_STATE,
      userData: null,
      phoneKebabOptions: [],
    };
  },
  computed: {
    getTimeString() {
      if (!this.booking) {
        return '';
      }
      const from = new ExtendedDate(this.booking.From);
      const until = new ExtendedDate(this.booking.Until);
      return `${from.localeTimeString()} - ${until.localeTimeString()}`;
    },
    resourceAvailable() {
      return this.stateString(true) === this.$t('Views.Book.available');
    },
    stateString() {
      return (i18n) => this.getStateString(i18n);
    },
    user() {
      // Default, take user from booking.
      if (!this.resource.Bookable) {
        const state = ZoneStateService.getRealmStateForZone(this.resource.Zid);
        const email = state?.Properties?.UName;

        if (!email) return {};
        if (email !== this.userData?.Username) {
          this.fetchUserData(email);
        }
        if (this.userData) {
          const { Name, AvatarUrl } = this.userData;
          return { Name, AvatarUrl };
        }
        return { Name: this.$t('Views.Book.guest'), AvatarUrl: null };
      }

      return this.booking?.User || {};
    },
    status() {
      return ZoneState.zoneStates[this.resource.Zid]?.Status;
    },
  },
  watch: {
    resource(newValue, oldValue) {
      if (!oldValue || newValue?.Zid !== oldValue?.Zid) {
        this.invalidate();
      }
    },
  },
  mounted() {
    this.invalidate();
  },
  methods: {
    async fetchUserData(email) {
      this.userData = await SearchService.getColleague(email);
    },
    getStateString(i18n = false) {
      switch (this.status) {
        case this.state.AVAILABLE:
          return i18n ? this.$t('Views.Book.available') : 'available';
        case this.state.AWAY:
          return i18n ? this.$t('Views.Book.booked') : 'booked';
        case this.state.OCCUPIED:
          return i18n ? this.$t('Views.Book.occupied') : 'occupied';
        case this.state.UNKNOWN:
        default:
          return i18n ? this.$t('Views.Book.available') : 'unbookable';
      }
    },

    // MARK: - Invalidate

    async invalidate() {
      await this.fetchBookingInfo();
      await this.preparePhoneKebabOptions();
    },

    async fetchBookingInfo() {
      this.booking = null;
      if (!this.resource) return;
      const zoneBookings = new ActiveZoneBookings(this.resource.Zid, new ExtendedDate().getTime(), new ExtendedDate().setHours(24, 0, 0, 0), true);
      await zoneBookings.init();
      await zoneBookings.caluclateAvailableSlotsInTimeline();

      if (zoneBookings.bookings && zoneBookings.bookings.length > 0) {
        this.booking = zoneBookings.bookings[0];
      }
    },

    async preparePhoneKebabOptions() {
      // Requires [{text: String, isDanger: bool, action: Function}]
      const options = [];
      this.phoneKebabOptions = [];

      const hasBooking = (!!this.booking);
      const isOrganizer = (hasBooking && this.booking.Owner === UserState.username);

      if (hasBooking && isOrganizer) {
        // Check-in
        if (BookingService.isCheckInNeededForBooking(this.booking)) {
          options.push({
            text: this.$t('Components.MapPopupItem.action_check_in'),
            isDanger: true,
            action: () => {
              this.checkIn();
            },
          });
        } else if (BookingService.checkIfCurrent(this.booking).isCurrent) {
          // Release resource
          options.push({
            text: this.$t('Components.MapPopupItem.action_release_resource'),
            isDanger: true,
            action: () => {
              this.releaseResource();
            },
          });
        }
      }

      // Add suggestions
      if (this.resource && this.resource.Bookable) {
        const { suggestions } = await BookingSuggestionsService.getSuggestions(this.resource.Zid);
        suggestions.forEach((it) => {
          options.push({
            text: `${it.title}, ${it.time}`,
            isDanger: false,
            action: () => {
              this.bookSuggestion(it);
            },
          });
        });
      }

      // View resource, option
      options.push({
        text: this.$t('Components.MapPopupItem.action_view_resource'),
        isDanger: false,
        action: () => {
          this.navigateToResourceView();
        },
      });

      // Add to kebab options
      this.phoneKebabOptions = options;
    },

    // MARK: - Actions

    async bookSuggestion(suggestion) {
      try {
        const data = await BookingSuggestionsService.createBooking(suggestion, this.resource.Zid);
        this.createdBooking = data;
        const evt = new CustomEvent(this.$t('Messages.created_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$router.push({ name: 'agenda', query: { date: this.$route.query.date } });
      } catch (e) {
        let evtMsg;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }
    },

    navigateToResourceView() {
      this.$router.push(`/book/${this.resource.Type}/${this.resource.Zid}`);
    },

    async checkIn() {
      const response = await BookingService.checkIn(this.booking);
      if (response.status === 200) {
        this.booking.CheckIn = response.data.CheckIn;
      }
    },

    async releaseResource() {
      try {
        await deleteBooking(this.booking.Bid);
        this.keepTemplate = false;
        const evt = new CustomEvent(this.$t('Components.EditBookingTab.remove_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$router.push('/agenda');
      } catch (e) {
        let evtMsg;
        this.keepTemplate = false;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.popup {
  display: flex;
  align-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  background: white;
  -webkit-box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 15px 6px rgba(0,0,0,0.1);
  border-radius: 1rem;
}

.loading-string {
  width: 5rem;
  height: 1rem;
  border-radius: 0.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  background: var(--primary-color-light);
}

.map-popup-timeline-time_container {
  flex-grow: 1;
  width: inherit;
}

.kebab_icon_container {
  margin-right: unset;
}

.map-popup-timeline-time_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.occupied {
    border-left: 8px solid var(--occupied-color);
  }
  &.booked {
    border-left: 8px solid var(--away-color);
  }
  &.available {
    border-left: 8px solid var(--free-color);
  }
  &.unbookable {
    border-left: 8px solid var(--color-resource-other);
  }

  .map-popup-information-side {
    display: flex;
    flex-direction: column;
    min-width: fit-content;

    h5{
      margin: 0;
    }
    p,h6{
      margin: 0;
    }
  }

  .map-popup-user-side {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    min-width: 0;
    overflow: hidden;

    .avatar--container {
      margin-left: 1rem;
    }

    p {
      text-align: end;
      min-width: 0;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

</style>
