<template>
  <div
    id="mapContainer"
    ref="mapContainer"
    :class="PhoneService.isInPhoneApp() ? 'phone_height' : 'web_height'"
  >
    <MapPopup
      v-if="phoneApp && queryZid && !zoneIsSpace && MapState.selectedZone"
      :resource="MapState.selectedZone"
      class="popup"
    />
    <MapUtility
      v-if="!phoneApp"
      :brand-date-time="UserState.user?.AbstractUser"
    />
    <NavigatorPopup />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars, object-shorthand */
/* eslint-disable vue/require-default-prop */
/* eslint-disable no-multiple-empty-lines */

/* States */
import UserState from '@/singletons/user.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import MapState from '@/singletons/map.state.singleton';

/* Controllers */
import * as LocationSelectionController from '@/views/location.selection/location.selection.controller';
import MapController from '@/classes/map/map.controller';

/* Services */
import PhoneService from '@/Services/Phone/phone.service';
import PhoneHeader from '@/Services/Phone/phone.header';

/* Mixins */
import QueryMixin from '@/mixins/query.mixin';

/* Components */
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import MapUtility from '@/views/map/components/map.utility.vue';
import MapPopup from './map.popup.item.vue';
import NavigatorPopup from './navigator.popup.vue';

export default {
  name: 'Map',
  components: {
    MapPopup,
    NavigatorPopup,
    MapUtility,
  },
  mixins: [QueryMixin],
  props: {
    mode: String, // "book", "sensor"
  },
  data() {
    return {
      hasLoaded: false,
      hasStartedInitialize: false,
      currentFloor: null,
      zoneStatus: null,
      zoneIsSpace: false,
      selectedZoneIsBookable: true,
      ZoneState,
      UserState,
      CompanyState,
      PhoneService,
      MapState,
      currentPhoneHeader: null,
      phoneKebabOptions: [],
      MapNavigatorController,
    };
  },
  computed: {
    queryZid() {
      return Number(this.$route.query.zid) || null;
    },
    phoneApp() {
      return PhoneService.isInPhoneApp();
    },
  },
  watch: {
    currentFloor() {
      this.onFloorplanChanged();
    },
    async $route(to) {
      const zid = to.params?.zid || this.queryZid;
      if (!this.queryZid) {
        return;
      }

      await MapState.hasFinnishedLoadingPromise;

      if (!PhoneService.isInPhoneApp()) {
        MapController.deselectMapLocation();
        MapState.selectedZone = CompanyState.zones.find((zone) => zone.Zid === Number(zid));
        MapController.selectionManager.panAndZoomToZone(MapState.selectedZone, 10);
      } else {
        MapController.showMapMarker(CompanyState.zones.find((zone) => zone.Zid === Number(this.queryZid)), true);
      }
    },
    'CompanyState.zones'() {
      this.init();
    },
    'ZoneState.zoneStateChanged'() {
      this.onZoneStateChanged();
    },
    'CompanyState.assets'() {
      this.init();
    },
    'MapState.selectedZone'() {
      this.invalidatePhoneHeader();
    },
    hasLoaded() {
      if (this.queryZid) {
        const zone = CompanyState.zones.find((z) => z.Zid === Number(this.queryZid));
        if (zone) MapState.selectedZone = zone;
      }
    },
  },
  destroyed() {
    this.destroyPhoneHeader();
    if (MapController.hasInitialized) {
      MapController.clear();
    }

    MapController.selectionManager.removeListener('on_zone_deselected', this.onZoneDeselected);
  },
  async mounted() {
    this.$nextTick(() => {
      if (!this.currentFloor) {
        this.currentFloor = this.getCurrentFloor();
      }
      this.setObservers();

      if (!this.hasLoaded && this.currentFloor) {
        this.init();
      }
    });
  },
  methods: {
    // MARK: - Initialize

    async init() {
      // if (!CompanyState.assets.length) { await CompanyService.getAssets(); }
      if (CompanyState.assets.length === 0) return;
      if (!this.hasLoaded && this.currentFloor) {
        if (this.hasStartedInitialize) {
          return;
        }

        this.hasStartedInitialize = true;
        const { mapContainer, mapPopup } = this.$refs;
        // Initialize floorplan
        await MapController.init({
          canvas: mapContainer, mode: this.mode, popup: mapPopup,
        });

        if (UserState.user?.AbstractUser) {
          // Initialize navigator if current user is abstract
          MapNavigatorController.init();
        }

        if (MapState.hasLoadedOnce) {
          MapState.hasFinnishedLoadingPromise = new Promise((resolve) => {
            MapState.hasFinnishedLoadingPromiseResolver = resolve;
          });
        }

        MapController.setFloor(this.currentFloor, () => {
          this.hasLoaded = true;
          this.onZoneStateChanged();
          MapState.hasFinnishedLoadingPromiseResolver(true);
          MapState.hasLoadedOnce = true;
          MapController.selectionManager.onZoneDeselected(this.onZoneDeselected);
        });
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.zid) {
          await MapState.hasFinnishedLoadingPromise;
          MapController.showMapMarker(CompanyState.zones.find((zone) => zone.Zid === Number(params.zid)), true);
        }

        // Setup observers
        this.setFloorplanObserversIfNeeded();
      }
    },

    // MARK: - Events & observers

    setObservers() {
      LocationSelectionController.eventBus.$on('onUserFloorplanChanged', (currentFloor) => {
        let selectedFloor = null;
        if (currentFloor && currentFloor.Zid) {
          selectedFloor = this.getZoneFromFloorId(currentFloor.Zid);
        }
        if (selectedFloor) {
          this.currentFloor = selectedFloor;
        }
      });
    },
    onZoneSelected(event) {
      if (PhoneService.isInPhoneApp() || UserState.user?.AbstractUser) {
        this.replaceQuery({ zid: event.id });
      } else {
        if (parseInt(this.$route.params.zid, 10) !== event.id && MapState.selectedZone?.Zid && MapState.selectedZone?.Type) {
          const { ignoreType, date } = this.$route.query;
          this.$router.push(`/book/${MapState.selectedZone.Type}/${event.id}?ignoreType=${ignoreType !== 'false'}&date=${date}`);
        }

        if (MapState.selectedZone) {
          MapController.selectionManager.panAndZoomToZone(MapState.selectedZone, 10);
        }
      }
    },
    onZoneDeselected() {
      if (this.$route.path.includes('book') && this.$route.name !== 'book') {
        // If in a /book/ sub-route, route back to /book when a zone is deselected
        this.$router.push({ path: '/book', query: { date: this.$route.query.date, ignoreType: true } });
      }
    },
    setFloorplanObserversIfNeeded() {
      if (!MapController.hasInitialized) {
        return;
      }

      // Listen for sensor selects && zone selects
      MapController.selectionManager.onZoneSelected(this.onZoneSelected);
    },

    onFloorplanChanged() {
      if (!this.hasStartedInitialize) {
        this.init();
        return;
      }
      if (!MapController.hasInitialized
      || !this.hasLoaded
      || this.currentFloor?.Zid === MapController?.getFloor()?.Zid) {
        return;
      }

      if (MapState.hasLoadedOnce) {
        MapState.hasFinnishedLoadingPromise = new Promise((resolve) => {
          MapState.hasFinnishedLoadingPromiseResolver = resolve;
        });
      }

      MapController.setFloor(this.currentFloor, () => {
        this.onZoneStateChanged();
        this.setFloorplanObserversIfNeeded();
        MapState.hasFinnishedLoadingPromiseResolver(true);
        MapState.hasLoadedOnce = true;
      });

      this.$root.$emit('onFloorChanged');
    },

    onZoneStateChanged() {
      if (!MapController.hasInitialized || !this.hasLoaded) { return; }
      MapController.onZoneStateChanged();
      this.placeMarkerIfNeeded();
    },

    // MARK: - Phone header

    destroyPhoneHeader() {
      if (this.currentPhoneHeader) {
        this.currentPhoneHeader.destroy();
        this.currentPhoneHeader = null;
      }
    },

    invalidatePhoneHeader() {
      // Clear existing
      this.destroyPhoneHeader();

      // Show resource title on phone.
      if (MapState.selectedZone) {
        this.currentPhoneHeader = new PhoneHeader(
          null,
          null,
          { back: true },
          MapState.selectedZone.Name,
        );
        this.currentPhoneHeader.backHandler = () => {
          this.destroyPhoneHeader();
          MapController.deselectMapLocation();
          this.removeQuery('zid');
        };
        this.currentPhoneHeader.create();

      // Show map title on phone when nothing is selected.
      } else {
        this.currentPhoneHeader = new PhoneHeader(
          null,
          null,
          { back: false },
          this.$t('Components.Sidebar.Map'),
        );
        this.currentPhoneHeader.backHandler = () => {};
        this.currentPhoneHeader.create();
      }
    },

    // MARK: - Convenience

    placeMarkerIfNeeded() {
      const { mapzone } = this.$route.query;
      const zid = ZoneState.activeZoneFilter || ZoneState.activeZoneQueryFilter || mapzone || MapState.selectedZone?.Zid;

      if (zid) {
        if (MapState.selectedZone !== null) {
          const resource = ZoneState.activeResources.find(({ Zid }) => Zid === Number(zid));
          if (PhoneService.isInPhoneApp()) {
            MapController.showMapMarker(resource);
          }
        }
      } else if (MapState.mode !== MapState.MODE_TYPE.navigator) {
        MapController.deselectMapLocation();
        this.removeQuery('zid');
      }
    },

    getCurrentFloor() {
      const currentFloor = UserState.selectedFloorplan;
      const savedFloorId = Number.parseInt(UserState.savedFloorplanId, 10);
      let currentZone = null;
      if (currentFloor && currentFloor.Zid) {
        currentZone = this.getZoneFromFloorId(currentFloor.Zid);
      }
      if (!currentZone && savedFloorId) {
        currentZone = this.getZoneFromFloorId(savedFloorId);
      }

      return currentZone;
    },

    getZoneFromFloorId(zid) {
      if (zid) {
        const foundZone = CompanyState.zones.find((zone) => zone.Zid === zid);
        if (foundZone) {
          return foundZone;
        }
      }

      return null;
    },
  },
};
</script>

<style lang="scss">


.full-width-height {
  width: 100%;
  height: 100%;
}

#mapContainer {
  position: relative;
  max-width: 100%;
  width: 100%;
  &.phone_height {
    height: 100%;
  }

  &.web_height {
    height: calc(100% - 5rem);
  }

  canvas {
    max-width: 100%;
  }

  .popup {
    position: absolute;
    width: 90%;
    bottom: 5%;
    left: 5%;
  }
}

.date-time {
  right: 0%;
  margin-right: 3rem;
  padding-bottom: 1.5rem;
}

.company-brand {
  margin-left: 3rem;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: 0.5s opacity 0.5s ease-in-out;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s ease-in-out;
}

</style>
