/* eslint-disable no-unused-vars, class-methods-use-this, brace-style, arrow-body-style */
import i18n from '@/i18n';
import { getTimeDifference } from '@/util/date_utils';

const alternatives = [
  {
    id: 1,
    name: i18n.t('Views.SensorSorting.overall_status'),
    getValueFromSensor: (sensor) => {
      const res = {};
      res.statuses = [0];
      alternatives.forEach((alt) => {
        if (alt.id !== 1 && alt.id !== 4) {
          const health = alt.getHealthStatusFromValue(alt.getValueFromSensor(sensor));
          res.statuses.push(health);
        }
      });
      res.status = Math.max(...res.statuses);
      res.status = Math.min(2, Math.max(0, res.status));
      res.status1Count = res.statuses.filter((it) => it === 1).length;
      res.status2Count = res.statuses.filter((it) => it === 2).length;
      return res;
    },
    getSortingValueFromValue: (value) => {
      if (!value) { return null; }
      return value.status1Count + (value.status2Count * 100);
    },
    getValueStringFromValue: (value) => {
      if (value.status === 0) {
        return 'OK';
      }
      if (value.status === 1) {
        return 'Warning';
      }
      if (value.status === 2) {
        return 'Critical';
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      return value.status;
    },
  },
  {
    id: 2,
    name: i18n.t('Views.SensorSorting.last_call'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.remote
      && sensor.remote.LastCall) {
        return new Date(sensor.remote.LastCall);
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return getTimeDifference(new Date(), new Date(value));
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      if (!value) {
        return 2;
      }
      const referenceTime = new Date();
      const diffInMs = (referenceTime.getTime() - value.getTime());

      // Error > 30min.
      if (diffInMs > (1000 * 60 * 30)) {
        return 2;
      }
      // Warning > 15min.
      if (diffInMs > (1000 * 60 * 15)) {
        return 1;
      }
      return 0;
    },
  },
  {
    id: 3,
    name: i18n.t('Views.SensorSorting.rssi'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.DeviceState
      && sensor.DeviceState.info
      && sensor.DeviceState.info.RSSI) {
        return sensor.DeviceState.info.RSSI;
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return `${value} dBm`;
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      if (!value) {
        return 2;
      }
      // Error < -85
      if (value < -85) {
        return 2;
      }
      // Warning < -70
      if (value < -70) {
        return 1;
      }
      return 0;
    },
  },
  {
    id: 4,
    name: i18n.t('Views.SensorSorting.session'),
    getValueFromSensor: (sensor) => {
      if (sensor?.remote?.SessLastActivity && sensor.remote?.SessionTimeout) {
        return sensor.remote.SessLastActivity + 300000;
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value && Date.now() <= value) {
        // return getTimeDifference(new Date(), new Date(value));
        return `${getTimeDifference(new Date(), new Date(value))} ${i18n.t('Views.Sensor.until_close')}`;
      }
      return i18n.t('Views.SensorSorting.no_active_session');
    },
    getHealthStatusFromValue: (value) => {
      if (Date.now() <= value) {
        return 0;
      }
      return 3;
    },
  },
  {
    id: 5,
    name: i18n.t('Views.SensorSorting.local_ip'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.remote
      && sensor.remote.LocalIp) {
        return sensor.remote.LocalIp;
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return value;
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      if (value) {
        return 0;
      }
      return 2;
    },
  },
  {
    id: 6,
    name: i18n.t('Views.SensorSorting.external_ip'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.remote
      && sensor.remote.ExternalIp) {
        return sensor.remote.ExternalIp;
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return value;
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      if (value) {
        return 0;
      }
      return 2;
    },
  },
  {
    id: 7,
    name: i18n.t('Views.SensorSorting.event_sent_recieved'),
    getValueFromSensor: (sensor) => {
      let val0 = 0;
      let val1 = 0;
      if (sensor
      && sensor.DeviceState
      && sensor.DeviceState.info) {
        val0 += sensor.DeviceState.info.m0 || 0;
        val0 += sensor.DeviceState.info.p0 || 0;
        val1 += sensor.DeviceState.info.m1 || 0;
        val1 += sensor.DeviceState.info.p1 || 0;
      }
      return Math.abs(val0 - val1);
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return String(value);
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      // Error
      if (value > 5) {
        return 2;
      }
      // Warning
      if (value > 2) {
        return 1;
      }
      // OK
      return 0;
    },
  },
  {
    id: 8,
    name: i18n.t('Views.SensorSorting.wifi_firmware'),
    getValueFromSensor: (sensor) => {
      // somtimes wifi fw is isolated in remote and sometimes in meta?? look into this
      let object = {};
      if (sensor?.remote?.Meta) {
        object = sensor?.remote?.Meta;
      } else {
        object = sensor?.remote;
      }
      if (object && object['wifi fw']) {
        return object['wifi fw'];
      }
      return null;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        return String(value);
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      // TODO: Return warning if not latest firmware?
      if (value) {
        return 0;
      }
      return 2;
    },
  },
  {
    id: 9,
    name: i18n.t('Views.SensorSorting.battery'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.DeviceState
      && sensor.DeviceState.power
      && sensor.DeviceState.power.battery) {
        return sensor.DeviceState.power;
      }
      return null;
    },
    getSortingValueFromValue: (value) => {
      if (!value) {
        return null;
      }
      return value.battery;
    },
    getValueStringFromValue: (value) => {
      if (value) {
        let vbat = 0;
        if (typeof value.VBat === 'number') {
          vbat = value.VBat / 10;
        }
        return `${String(value.battery)}%, ${vbat} V`;
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      // Error
      if (value && value.VUSB === 0 && value.battery <= 10) {
        return 2;
      }
      // Warning
      if (value && value.VUSB === 0 && value.battery < 20) {
        return 1;
      }
      // OK
      return 0;
    },
  },
  {
    id: 10,
    name: i18n.t('Views.SensorSorting.usb_voltage'),
    getValueFromSensor: (sensor) => {
      if (sensor
      && sensor.DeviceState
      && sensor.DeviceState.power) {
        return {
          ...sensor.DeviceState.power,
          onBattery: (sensor.DeviceState.power.VUSB === 0),
        };
      }
      return null;
    },
    getSortingValueFromValue: (value) => {
      if (!value || value.onBattery) {
        return null;
      }
      return value.VUSB;
    },
    getValueStringFromValue: (value) => {
      if (value && value.onBattery) {
        return i18n.t('Views.SensorSorting.on_battery');
      }
      if (value) {
        return `${String(value.VUSB / 10)} V`;
      }
      return '-';
    },
    getHealthStatusFromValue: (value) => {
      // Error
      if (value && !value.onBattery && value.VUSB < 47) {
        return 2;
      }
      // Warning
      if (value && !value.onBattery && value.VUSB < 50) {
        return 1;
      }
      // OK
      return 0;
    },
  },
];

export default alternatives;
