/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars, class-methods-use-this, brace-style, prefer-destructuring */

import moment from 'moment';
import UserState from '@/singletons/user.state.singleton';

import { forceCloseSensorSession } from '@/util/api/api.sensors';
import BookingService from '@/Services/Bookings/bookings.service';
import BookingState from '@/singletons/bookings.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';
import CompanyService from '@/Services/Company/company.service';
import ExtendedDate from '@/classes/extended.date.class';
// import Socket from '@/singeltons/socket.state.singelton.js';

import {
  getSensors,
  getSensorsRemote,
  getSensorRemoteById,
  getSensorById,
} from '@/util/api';
import SortingAlternatives from './sensor.sorting.algorithm';

class SensorService {
  constructor() {
    this.isFetching = false;
    this.isFetchingDone = false;
    this.isFetchingSensorRemote = false;
    this.isFetchingSensorRemoteDone = false;
    this.hasLoadedOnce = false;

    this.zones = [];
    this.zonesIndexMap = {};

    this.assets = [];
    this.bookings = [];

    this.sensorsFetchedAt = null;
    this.sensors = [];
    this.sensorsRemote = [];
    this.sensorsFiltered = [];

    this.searchPhrase = '';

    this.sensorsIndexMap = {};
    this.selectedSensor = null;
    this.selectedSensorLog = [];
    // Updating sensor, events often triggers with a few milliseconds of one another
    // then it is not neccessray to update twice/thrice etc, set is used with timeout to make
    // sure not to many request are sent to remote/core
    this.updatingSensors = new Set();
    this.sensorsToAnimate = []; // Used to know when to animte senors on map
    this.filters = []; // Used for filtering the sensorlist
    this.wifiFw = []; // Used for sensors wifi Firmware in filter
    this.pointOptions = []; // Used for the access point filter for sensors

    this.sortAlternatives = SortingAlternatives;
    this.selectedSortAlternative = SortingAlternatives[0];
    this.selectedSortOrder = 'desc';
  }

  computed = {
    isFetchingAllDone() {
      return (this.isFetchingDone && this.isFetchingSensorRemoteDone);
    },
  };

  async init() {
    this.isFetching = true;
    this.isFetchingDone = false;

    await Promise.all([
      CompanyService.getCompanies(),
      this.fetchZones(),
      this.fetchAssets(),
      this.fetchBookigsForToday(),
      this.fetchSensorsIfNeeded(),
    ]);
    this.attachBookingsToZones();

    this.isFetching = false;
    this.isFetchingDone = true;
    this.hasLoadedOnce = true;

    if (this.filters.length > 0) {
      this.filterOnFilterOptions();
    }

    if (this.searchPhrase.length > 0) {
      this.filterOnSearchPhrase();
    }
  }

  // MARK: - Fetches of data

  async fetchAssets() {
    await CompanyService.getAssets();
    this.assets = CompanyState.assets;
  }

  async fetchZones() {
    await CompanyService.getZones();
    this.zones = CompanyState.zones;

    this.zonesIndexMap = {};
    for (let i = 0; i < this.zones.length; i += 1) {
      const zone = this.zones[i];
      this.zonesIndexMap[zone.Zid] = i;
    }
  }

  updateCurrentLog(did, data) {
    if (did && this.selectedSensor?.Did === did) {
      this.selectedSensorLog.push(data);
    }
  }

  addToUpdatingQueue(did) {
    this.updatingSensors.add(did);
    setTimeout(() => { this.updatingSensors.delete(did); }, 1000);
  }

  updateSensor(did) {
    const index = this.sensorsFiltered.findIndex((sensor) => sensor.Did === did);
    if (index >= 0 && !this.updatingSensors.has(did)) {
      this.addToUpdatingQueue(did);
      // Always update list -> to animate sensors and to have list updated when exiting sensor detail view
      this.updateSensorInList(did);
      if (this.selectedSensor?.Did === did) {
        this.updateSensorInView(did);
      }
    }
  }

  async updateSensorInView(did) {
    const newSensorData = await this.getSensorFromCoreAndRemoteById(did);
    if (newSensorData?.remote) { this.selectedSensor.remote = newSensorData?.remote; }
    this.selectedSensor.remote = newSensorData?.remote;
  }

  async updateSensorInList(did) {
    const oldSensor = this.sensorsFiltered.find((sensor) => sensor.Did === did);

    /* getSensorFromCoreAndRemoteById(did) does not generate sensor object identical
    to the getSensors function so issues arieses when comparing and evaluating its key/values
    Therefore we need to fetch all sensros remote and filter on it atm (Needs update in backend in future) */
    const sensorResponse = await getSensorById(did);
    const newSensor = sensorResponse.data;
    const response = await getSensorsRemote();
    const remoteData = response.data.find((sensor) => sensor.Did === did);
    // const newSensor = { ...sensorResponse.data, remote: remoteData };
    newSensor.remote = remoteData;

    if (remoteData) newSensor.remote.SessionTimeout = this.getSessionLength(did);

    const sortingAlt = this.selectedSortAlternative;

    const oldVal = sortingAlt.getValueFromSensor(oldSensor);
    const newVal = sortingAlt.getValueFromSensor(newSensor);

    /* If newVal is object then sortingAlt is overall, should always animate
    else only animate if oldVal for selected category is different than newVal */
    if ((typeof newVal === 'object' || oldVal !== newVal)) {
      this.sensorsToAnimate.push(newSensor.Did);
    }

    const index = this.sensorsFiltered.findIndex((sensor) => sensor.Did === did);
    // Splice to mutate
    this.sensorsFiltered.splice(index, 1, newSensor);
    this.sortSensorFilteredBy(this.selectedSortAlternative, this.selectedSortOrder);
    // this.searchSensors();
  }

  // session is always 5 min, update to dynamic in future ?
  getSessionLength(did) {
    return 300000;
  }

  async fetchBookigsForToday() {
    const todayStart = moment.utc().set({
      hour: 0, minute: 0, second: 0, millisecond: 0,
    });
    const todayEnd = todayStart.clone().add(1, 'days');
    await BookingService.getAllBookings(todayStart.valueOf(), todayEnd.valueOf());
    this.bookings = BookingState.bookings;
  }

  async fetchSensorsIfNeeded() {
    if (UserState.selectedCid) {
      this.isFetchingSensorRemote = true;
      this.isFetchingSensorRemoteDone = false;

      this.setSelectedSensor(null);
      this.selectedselectedSensorLog = [];

      await Promise.all([
        this.fetchSensorsFor(UserState.selectedCid),
        this.fetchSensorsRemote(),
      ]);
      this.sensorsFetchedAt = new Date();
      this.setRemoteSensorsOnSensorModels();

      // Note, default filter to type "SENSE" & sort
      this.setSensorFilterSense();
      this.setSelectedSortAlternative(this.selectedSortAlternative);

      this.isFetchingSensorRemote = false;
      this.isFetchingSensorRemoteDone = true;
    }
  }

  // Clears all the filters, sorting, searchphrases etc.
  clearFilters() {
    this.filters = [];
    this.wifiFw = [];
    this.pointOptions = [];
    this.searchPhrase = '';
    this.selectedSortAlternative = SortingAlternatives[0];
    this.selectedSortOrder = 'desc';
    this.setSensorFilterSense(); // Easy way to update the list
  }

  async fetchSensorsFor(cid) {
    const { data } = await getSensors(cid);
    this.sensors = data || [];

    this.sensorsIndexMap = {};
    for (let i = 0; i < this.sensors.length; i += 1) {
      const sensor = this.sensors[i];
      this.sensorsIndexMap[sensor.Did] = i;
    }
  }

  async forceCloseSession(did) {
    const id = did || this.selectedSensor.Did;
    const response = await forceCloseSensorSession(id);
    if (response.status === 201) return true;
    return false;
  }

  async fetchSensorsRemote() {
    const { data } = await getSensorsRemote();
    this.sensorsRemote = data || [];
  }

  // MARK: - Concatinate data models

  attachBookingsToZones() {
    for (let i = 0; i < this.bookings.length; i += 1) {
      const booking = this.bookings[i];
      if (!!booking && !!booking.Zid) {
        const zone = this.zones[this.zonesIndexMap[booking.Zid]];
        if (zone) {
          if (!zone.bookings) {
            zone.bookings = [];
          }
          zone.bookings.push(booking);
        }
      }
    }
  }

  setRemoteSensorsOnSensorModels() {
    for (let i = 0; i < this.sensors.length; i += 1) {
      this.sensors[i].remote = null;
    }

    for (let i = 0; i < this.sensorsRemote.length; i += 1) {
      const sensorRemote = this.sensorsRemote[i];
      const sensorIndex = this.sensorsIndexMap[sensorRemote.Did];

      if (typeof sensorIndex === 'number' && this.sensors[sensorIndex]) {
        this.sensors[sensorIndex].remote = sensorRemote;
      }
    }
  }

  // MARK: - Determine sensor health
  // TODO: Caching values might be nicer.

  getOverallHealthForSensor(sensor) {
    const overallAlt = this.sortAlternatives[0];
    if (overallAlt && sensor) {
      const val = overallAlt.getValueFromSensor(sensor);
      return overallAlt.getHealthStatusFromValue(val);
    }
    return 0;
  }

  // MARK: - Sort alternatives

  sortSensorFilteredBy(alternative, order) {
    const orderBy = (order.trim().toLowerCase() === 'asc') ? 0 : 1;

    this.sensorsFiltered = this.sensorsFiltered.sort((sensor0, sensor1) => {
      let val0 = alternative.getValueFromSensor(sensor0);
      let val1 = alternative.getValueFromSensor(sensor1);
      if (typeof alternative.getSortingValueFromValue === 'function') {
        val0 = alternative.getSortingValueFromValue(val0);
        val1 = alternative.getSortingValueFromValue(val1);
      }
      const val0num = typeof val0 === 'number';
      const val1num = typeof val1 === 'number';

      // Nulls at bottom
      if ((!val0 && !val0num) && val1) { return 1; }
      if (val0 && (!val1 && !val1num)) { return -1; }
      if ((!val0 && val0num) && (!val1 && val1num)) { return 0; }

      // Sort on value
      if (orderBy === 0) {
        if (val0 < val1) { return -1; }
        if (val0 > val1) { return 1; }
      }
      else {
        if (val0 < val1) { return 1; }
        if (val0 > val1) { return -1; }
      }

      return 0;
    });
  }

  setSelectedSortAlternative(alternative, order) {
    const orderToUse = order || this.selectedSortOrder || 'asc';
    this.selectedSortAlternative = alternative;
    this.selectedSortOrder = orderToUse;
    this.sortSensorFilteredBy(alternative, orderToUse);
  }

  // MARK: - Select sensors

  setSelectedSensor(sensorId) {
    if (sensorId && this.selectedSensor && sensorId === this.selectedSensor.Did) {
      return;
    }
    if (sensorId) {
      const sensor = this.sensors.find((it) => (it.Did === sensorId));
      this.selectedSensor = sensor;
    } else {
      this.selectedSensor = null;
    }
  }

  // MARK: - Sensor filters

  /* This function gets the different firmware and accesspoint options for filtering sensors */
  getWifiFirmwareAndAccessPointOptions() {
    const setFirmware = new Set();
    const setPointOptions = new Set();
    this.wifiFw = [];
    this.pointOptions = [];

    this.sensors.forEach((sensor) => {
      setFirmware.add(sensor?.remote?.['wifi fw']);
      setPointOptions.add(`${sensor?.remote?.SSID}, ${sensor?.remote?.BSSID}`);
    });
    setFirmware.forEach((wifiFw) => {
      if (wifiFw !== undefined) {
        this.wifiFw.push({ text: wifiFw, value: wifiFw });
      }
    });
    setPointOptions.forEach((SSID) => {
      if (!SSID.includes('undefined')) {
        this.pointOptions.push({ text: SSID, value: SSID.split(',')[1].trim() });
      }
    });
  }

  filterOnSearchPhrase() {
    // reset sensors search filter
    this.setSensorFilterSense();
    const string = this.searchPhrase.toLowerCase().trim();
    if (!string) return;
    const filtered = this.sensorsFiltered.filter((sensor) => sensor.Did.toLowerCase().includes(string) || sensor.Zone?.Name?.toLowerCase().includes(string));
    this.sensorsFiltered = filtered;

    if (this.filters.length > 0) {
      this.filterOnFilterOptions();
    }
  }

  /* This function filters the sensors for the selected floor depending on what filters are selected.
  Move to another file maybe?
  Needs to be reworked it is not good...
  */
  filterOnFilterOptions() {
    let list = [];
    const sensors = this.sensorsFiltered;
    this.filters.forEach((filter) => {
      switch (filter) {
        case 'MOTION':
          if (list.length > 0) {
            const templist = sensors.filter((sensor) => sensor.SubType === 'MOTION');
            list = this.addNonDuplicatesAndFilter(list, templist, filter);
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor.SubType === 'MOTION'));
          break;
        case 'PRESENCE':
          if (list.length > 0) {
            const templist = sensors.filter((sensor) => sensor.SubType === 'PRESENCE');
            list = this.addNonDuplicatesAndFilter(list, templist, filter);
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor.SubType === 'PRESENCE'));
          break;
        case 'SIGNALS':
          if (list.length > 0) {
            const templist = sensors.filter((sensor) => sensor.Type === 'SIGNALS');
            list = this.addNonDuplicatesAndFilter(list, templist, filter);
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor.Type === 'SIGNALS'));
          break;
        case 'resource':
          if (list.length > 0) {
            list = list.filter((sensor) => sensor.Zone !== null);
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor.Zone !== null));
          break;
        case '24hrs':
          if (list.length > 0) {
            list = list.filter((sensor) => sensor.LastModified >= new ExtendedDate().yesterday());
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor.LastModified >= new ExtendedDate().yesterday()));
          break;
        case 'session':
          if (list.length > 0) {
            list = list.filter((sensor) => sensor?.remote?.SessLastActivity >= new ExtendedDate().getTime() - 300000);
            break;
          }
          list = list.concat(sensors.filter((sensor) => sensor?.remote?.SessLastActivity >= new ExtendedDate().getTime() - 300000));
          break;
        default:
          if (filter.includes(':')) {
            if (list.length > 0) {
              const templist = sensors.filter((sensor) => sensor?.remote?.BSSID === filter
              && list.find((sensorLst) => sensor?.remote?.['wifi fw'] === sensorLst?.remote?.['wifi fw']));
              list = this.addNonDuplicatesAndFilter(list, templist, filter);
              break;
            }
            if (list.length === 0) {
              list = list.concat(sensors.filter((sensor) => sensor?.remote?.BSSID === filter));
              break;
            }
            break;
          } else if (filter.includes('.')) {
            if (list.length > 0) {
              const templist = sensors.filter((sensor) => sensor?.remote?.['wifi fw'] === filter
              && list.find((sensorLst) => sensor?.remote?.BSSID === sensorLst?.remote?.BSSID));
              list = this.addNonDuplicatesAndFilter(list, templist, filter);
              break;
            }
            if (list.length === 0) {
              list = list.concat(sensors.filter((sensor) => sensor?.remote?.['wifi fw'] === filter));
              break;
            }
            break;
          }
          break;
      }
    });
    this.sensorsFiltered = list;
  }
  /* also really dislike this function and would rather rework it all at some point */

  addNonDuplicatesAndFilter(list, templist, filter) {
    templist.forEach((sensor) => {
      if (!list.find((sensorL) => sensorL.Did === sensor.Did)) {
        list.push(sensor);
      }
    });
    if (filter.includes('.') || filter.includes(':')) {
      return list.filter((sensor) => sensor?.remote?.BSSID === filter || sensor?.remote?.['wifi fw'] === filter);
    }
    if (filter === 'MOTION' || filter === 'PRESENCE') {
      if (this.filters.find((filterInList) => filterInList.includes(':'))) {
        return list.filter((sensor) => (sensor.SubType === filter || sensor.Type === filter)
        && this.filters.find((filterList) => filterList === sensor?.remote?.BSSID));
      }

      if (this.filters.find((filterInList) => filterInList.includes('.'))) {
        return list.filter((sensor) => (sensor.SubType === filter || sensor.Type === filter)
        && this.filters.find((filterList) => filterList === sensor?.remote?.['wifi fw']));
      }

      if (list.find((sensor) => sensor.SubType !== filter)) {
        return list;
      }
      return list.filter((sensor) => sensor.SubType === filter || sensor.Type === filter);
    }
    if ((this.filters.includes('MOTION') || this.filters.includes('PRESENCE'))
     && this.filters.findIndex((filterIndex) => filterIndex === 'MOTION' || filterIndex === 'PRESENCE') < 2) {
      if (this.filters.find((filterInList) => filterInList.includes(':'))) {
        return list.filter((sensor) => this.filters.find((filterList) => filterList === sensor.SubType || filterList === sensor.Type)
        && this.filters.find((filterList) => filterList === sensor?.remote?.BSSID));
      }

      if (this.filters.find((filterInList) => filterInList.includes('.'))) {
        return list.filter((sensor) => this.filters.find((filterList) => filterList === sensor.SubType || filterList === sensor.Type)
        && this.filters.find((filterList) => filterList === sensor?.remote?.['wifi fw']));
      }
    }
    return list;
  }

  filterSensorsToSelectedFloor(list) {
    return list.filter((sensor) => {
      const hasZone = (sensor.Zone && sensor.Zone.ParentZone);
      const hasSameFloor = (
        hasZone
        && String(sensor.Zone.ParentZone).trim() === String(UserState.savedFloorplanId).trim()
      );
      return (!hasZone || hasSameFloor);
    });
  }

  setSensorFilterAll() {
    this.sensorsFiltered = this.filterSensorsToSelectedFloor(
      [].concat(this.sensors),
    );
  }

  setSensorFilterNone() {
    this.sensorsFiltered = [];
  }

  setSensorFilterSense() {
    this.sensorsFiltered = this.filterSensorsToSelectedFloor(
      this.sensors.filter((sensor) => sensor.Type === 'SENSE' /* || sensor.Type === 'SIGNALS' Hide the sensor until we implement them better */),
    );
  }

  // MARK: - Getters

  getSensorById(id) {
    const sensorIndex = this.sensorsIndexMap[id];
    if (typeof sensorIndex === 'number') {
      return this.sensors[sensorIndex];
    }
    return null;
  }

  async getSensorFromCoreAndRemoteById(sensorId) {
    const [coreSensorResult, remoteSensorResult] = await Promise.allSettled([
      getSensorById(sensorId),
      getSensorRemoteById(sensorId),
    ]);

    if (coreSensorResult.status === 'rejected') {
      return null;
    }

    const coreSensor = coreSensorResult.value.data;

    if (remoteSensorResult.status === 'fulfilled') {
      const remoteSensor = remoteSensorResult.value.data;
      coreSensor.remote = remoteSensor;
    }

    return coreSensor;
  }
}

export default new SensorService();
