/* eslint-disable import/prefer-default-export */

function countUp(diffInMs) {
  if (diffInMs < 1000 * 60) { // Seconds, < 1 min
    return `${Math.round(diffInMs / 1000)} sec`;
  }
  if (diffInMs < 1000 * 60 * 60) { // Minutes, < 1 hour
    return `${Math.round(diffInMs / 1000 / 60)} min`;
  }
  if (diffInMs < 1000 * 60 * 60 * 24) { // Hours, < 1 day
    const val = Math.round(diffInMs / 1000 / 60 / 60);
    return `${val} hour${(val > 1) ? 's' : ''}`;
  }

  // Days + hours
  let res = '';
  // Days
  const days = Math.floor(diffInMs / 1000 / 60 / 60 / 24);
  if (days > 0) {
    res = `${days} day${days !== 1 ? 's' : ''}`;
  }
  // Hours
  const hours = Math.round((diffInMs / 1000 / 60 / 60) - (days * 24));
  if (days === 0 || (days > 0 && hours > 0)) {
    if (days > 0) { res += ' '; }
    res += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
  return res;
}

function countDown(diffInMs) {
  if (diffInMs < 1000 * 60) { // Seconds, < 1 min
    return `${Math.round((diffInMs % (1000 * 60)) / 1000)} sec`;
  }
  if (diffInMs < 1000 * 60 * 60) { // Minutes, < 1 hour
    return `${Math.round((diffInMs % (1000 * 60 * 60)) / (1000 * 60))} min`;
  }
  if (diffInMs < 1000 * 60 * 60 * 24) { // Hours, < 1 day
    const val = Math.round((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${val} hour${(val > 1) ? 's' : ''}`;
  }

  // Days + hours
  let res = '';
  // Days
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  if (days > 0) {
    res = `${days} day${days !== 1 ? 's' : ''}`;
  }
  // Hours
  const hours = Math.round((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + (days * 24));
  if (days === 0 || (days > 0 && hours > 0)) {
    if (days > 0) { res += ' '; }
    res += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
  return res;
}

export const getTimeDifference = (date, nowReference) => {
  const now = nowReference || new Date();
  const diffInMs = (now.getTime() - date.getTime());
  if (diffInMs) return (diffInMs >= 0) ? countUp(diffInMs) : countDown(Math.abs(diffInMs));
  return null;
};
