<template>
  <div
    class="map relative"
  >
    <MapComponent
      :zones="SensorService.zones"
      :assets="SensorService.assets"
      mode="book"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars, object-shorthand */
import CompanyState from '@/singletons/company.state.singleton';
import SensorService from '@/Services/Sensors/sensor.service';
import MapComponent from '@/views/map/components/map.component.vue';

export default {
  components: {
    MapComponent,
  },
  data() {
    return {
      CompanyState,
      SensorService,
    };
  },
};

</script>

<style scoped>

.map{
  height: 100%;
  width: 100%;
}
.map-and-page.show-only-map{
  grid-template-columns: 0em 1fr;
}

#select-floorplan {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 16px 16px 16px 16px;
}

.relative {
  position: relative;
}

</style>
